import { z } from "zod";

const AppReadyPayloadSchema = z.object({
  mobileKeyboardBarHeight: z.optional(z.number()),
  isLoggedIn: z.optional(z.boolean()),
  isOnboarded: z.optional(z.boolean()),
});

const StoragePayloadSchema = z.object({
  requestId: z.number(),
  result: z.any(),
});

const ClipboardPayloadSchema = z.object({
  requestId: z.number(),
  result: z.object({
    error: z.string().optional(),
    data: z.string(),
  }),
});

const AudioChunkPayloadSchema = z.object({
  data: z.string(),
  playlist_id: z.string(),
  chunk_no: z.number(),
  duration: z.number(),
});

const RecordAudioPayloadSchema = z.union([
  z.object({
    audioId: z.string(),
    message: z.literal("startRecording"),
  }),
  z.object({
    audioId: z.string(),
    message: z.literal("startRecordingFailed"),
  }),
  z.object({
    audioId: z.string(),
    message: z.literal("recordingStarted"),
  }),
  z.object({
    audioId: z.string(),
    message: z.literal("endRecording"),
    audioChunk: AudioChunkPayloadSchema.optional(),
  }),
]);

const ImagePayloadSchema = z.union([
  z.object({
    imageURL: z.string(),
    note: z.string(),
  }),
  z.object({
    imageExtension: z.string(),
    imageData: z.string(),
    note: z.string(),
  }),
]);

const NativeResponseSchema = z.union([
  z.object({
    type: z.literal("appReady"),
    payload: AppReadyPayloadSchema,
  }),
  z.object({
    type: z.literal("storage"),
    payload: StoragePayloadSchema,
  }),
  z.object({
    type: z.literal("clipboard"),
    payload: ClipboardPayloadSchema,
  }),
  z.object({
    type: z.literal("getMemoryUsage"),
    payload: z.number().nullable(),
  }),
  z.object({
    type: z.literal("importLink"),
    payload: z.object({
      url: z.string(),
      note: z.string(),
    }),
  }),
  z.object({
    type: z.literal("closeKeyboard"),
    payload: z.string(),
  }),
  z.object({
    type: z.literal("createNote"),
    payload: z.string(), // This just here for consistency; expect ""
  }),
  z.object({
    type: z.literal("startRecordingNote"),
    payload: z.string(),
  }),
  z.object({
    type: z.literal("importRecordedNote"),
    payload: z.object({
      note: z.string(),
    }),
  }),
  // Initiates recording in the native app
  z.object({
    type: z.literal("recordAudio"),
    payload: RecordAudioPayloadSchema,
  }),
  z.object({
    type: z.literal("audioVolumeData"),
    payload: z.object({
      playlist_id: z.string(),
      volume: z.number(),
    }),
  }),
  z.object({
    type: z.literal("importEncodedAudioChunk"),
    payload: AudioChunkPayloadSchema,
  }),
  z.object({
    type: z.literal("importText"),
    payload: z.object({
      url: z.string().optional(),
      note: z.string(),
    }),
  }),
  z.object({
    type: z.literal("importImage"),
    payload: ImagePayloadSchema,
  }),
  z.object({
    type: z.literal("error"),
    payload: z.string(),
  }),
]);

export type NativeResponse = z.infer<typeof NativeResponseSchema>;
export type StoragePayload = z.infer<typeof StoragePayloadSchema>;
export type ClipboardPayload = z.infer<typeof ClipboardPayloadSchema>;
export type ImagePayload = z.infer<typeof ImagePayloadSchema>;

const base64JsonTypes = [
  "importLink",
  "importText",
  "importRecordedNote",
  "recordAudio",
  "audioVolumeData",
  "importEncodedAudioChunk",
  "importImage",
];

export const safeParseNativeResponse = (
  message: unknown,
): { success: true; data: NativeResponse } | { success: false; error: Error } => {
  try {
    // Validate the message is an object with a type and payload field
    if (message === null || typeof message !== "object") {
      throw new Error("Message is not an object");
    } else if (!("type" in message) || typeof message.type !== "string") {
      throw new Error("Message does not have a type field");
    } else if (!("payload" in message)) {
      throw new Error("Message does not have a payload field");
    }
    // Decode the payload field for payloads that will be base64-encoded JSON
    if (base64JsonTypes.includes(message.type)) {
      if (typeof message.payload !== "string") {
        throw new Error(`Expected payload to be a string for ${base64JsonTypes}`);
      }
      const payloadString = Buffer.from(message.payload, "base64").toString();
      message.payload = JSON.parse(payloadString);
    }
    return {
      success: true,
      data: NativeResponseSchema.parse(message),
    };
  } catch (e) {
    const error = e instanceof Error ? e : new Error("Unknown error");
    return { success: false, error };
  }
};
