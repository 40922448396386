import { useEffect } from "react";
import { getDefaultStore, useAtomValue } from "jotai";
import { EditorView } from "prosemirror-view";
import webViewStorageHandler from "../storage/WebViewStorageHandler";
import clipboardManager from "../editorPage/utils/ClipboardManager";
import { isRunningInIOSWebview } from "../utils/environment";
import appLogger from "../utils/logger";
import { handleImportImageFromNative } from "../editor/utils/clipboard/imageTools";
import { ApiClient } from "../api/client";
import { processTranscriptReplacements } from "../../shared/transcription";
import { MOBILE_BAR_HEIGHT } from "../utils/constants";
import { editorViewAtom, iosPhysicalMemoryAtom } from "../model/atoms";
import { globalAudioRecordingCoordinator } from "../editor/features/audioInsert/audioRecordingCoordinator";
import { TopLevelToken } from "../../shared/types";
import { getAuth } from "../auth/auth";
import { useCreateAudioAtTopAsSoonAsPossible, useCreateNoteAtTopAsSoonAsPossible } from "./hooks";
import { NativeResponse, safeParseNativeResponse } from "./types";

const logger = appLogger.with({ namespace: "useNativeResponseHandler" });

export const useNativeResponseHandler = () => {
  const create = useCreateNoteAtTopAsSoonAsPossible();
  const insertAudio = useCreateAudioAtTopAsSoonAsPossible();
  const editorView = useAtomValue(editorViewAtom);

  useEffect(() => {
    if (!isRunningInIOSWebview) return;

    window.nativeResponse = (v: unknown) => {
      const result = safeParseNativeResponse(v);
      if (!result.success) {
        logger.error("Error parsing native response", { error: result.error });
        return;
      }
      handleNativeResponse(result.data, create, insertAudio, editorView);
    };

    getAuth().then((auth) => {
      window.webkit?.messageHandlers.appReady.postMessage({
        type: "appReady",
        mobileKeyboardBarHeight: MOBILE_BAR_HEIGHT,
        isLoggedIn: auth && auth.type === "logged-in",
      });
    });

    // Get initial memory usage periodically
    window.webkit?.messageHandlers.getMemoryUsage.postMessage({ type: "getMemoryUsage" });
    const memoryUsageInterval = setInterval(() => {
      window.webkit?.messageHandlers.getMemoryUsage.postMessage({ type: "getMemoryUsage" });
    }, 20_000);

    return () => clearInterval(memoryUsageInterval);
  }, [create, insertAudio, editorView]);
};

async function handleNativeResponse(
  response: NativeResponse,
  create: (content: string | TopLevelToken[]) => void,
  insertAudio: () => void,
  editorView: EditorView | null,
) {
  const { type, payload } = response;
  switch (type) {
    case "appReady":
      break;
    case "storage":
      webViewStorageHandler.handleStorageResponse(payload);
      break;
    case "clipboard":
      clipboardManager.handleClipboardResponse(payload);
      break;
    case "getMemoryUsage": {
      if (payload === null) return;
      const bytes = payload;
      const kb = bytes / 1024;
      const mb = kb / 1024;
      const formattedSize = mb >= 1 ? `${Math.round(mb * 10) / 10} MB` : `${Math.round(kb)} KB`;
      logger.info(`iOS physical memory usage: ${formattedSize}`);
      getDefaultStore().set(iosPhysicalMemoryAtom, payload);
      break;
    }
    case "importLink": {
      const { url, note } = payload;
      if (note) {
        create(`${note} - ${url}`);
      } else {
        ApiClient()
          .links.unfurl(url)
          .then((res) => {
            create(res.title ? `${res.title} - ${url}` : url);
          });
      }
      break;
    }
    case "closeKeyboard": {
      if (editorView) {
        editorView.dom.blur();
      }
      break;
    }
    case "importText": {
      create(payload.note);
      break;
    }
    case "startRecordingNote": {
      insertAudio();
      break;
    }
    case "importRecordedNote": {
      processTranscriptReplacements(payload.note)
        .split("\n--\n")
        .toReversed()
        .forEach((chunk) => {
          create(chunk);
        });
      break;
    }
    case "createNote": {
      create(payload);
      break;
    }
    case "recordAudio": {
      const { audioId, message } = payload;
      switch (message) {
        case "startRecording": // Should never appear here
          break;
        case "endRecording": {
          if (payload.audioChunk) {
            const { data, playlist_id, chunk_no, duration } = payload.audioChunk;
            await globalAudioRecordingCoordinator.handleiOSAudioChunk(data, playlist_id, chunk_no, duration);
          }
          globalAudioRecordingCoordinator.finishiOSAudioRecording(audioId);
          break;
        }
        case "recordingStarted":
          break;
        case "startRecordingFailed":
          globalAudioRecordingCoordinator.handleiOSRecordingFailed(audioId);
          break;
      }
      break;
    }
    case "audioVolumeData": {
      const { playlist_id, volume } = payload;
      globalAudioRecordingCoordinator.updateiOSAudioVolume(playlist_id, volume);
      break;
    }
    case "importEncodedAudioChunk": {
      const { data, playlist_id, chunk_no, duration } = payload;
      globalAudioRecordingCoordinator.handleiOSAudioChunk(data, playlist_id, chunk_no, duration);
      break;
    }
    case "importImage": {
      handleImportImageFromNative(create, payload);
      break;
    }
    case "error":
      logger.error("Error from native code", { error: payload });
      break;
    default:
      break;
  }
}
