import styled from "@emotion/styled";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { chainCommands } from "prosemirror-commands";
import { redo, undo } from "prosemirror-history";
import { Selection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  AtSign,
  CheckSquare,
  ChevronLeft,
  ChevronRight,
  Clipboard,
  Edit,
  Hash,
  Image as ImageIcon, // Rename to avoid lint warnings about improper use of Image component
  Camera,
  List,
  Mic,
  StopCircle,
  Plus,
  RotateCcw,
  RotateCw,
} from "react-feather";
import { trackEvent } from "../analytics/analyticsHandlers";
import { selectionToHashtag, selectionToReference } from "../editor/features/autocomplete/replaceSelection";
import { autocompleteStateAtom } from "../editor/features/autocomplete/useAutocompleteState";
import { toggleCheckboxCommand } from "../editor/features/checkbox/toggleCheckboxCommand";
import { listCreateOrWrap } from "../editor/features/list/createNestedListPlugin";
import { decrementDepthCommand, incrementDepthCommand } from "../editor/features/list/handleListActionsPlugin";
import { splitNoteCommand } from "../editor/features/note/splitNoteCommand";
import { dedent, indent } from "../editor/features/text/tabCommands";
import { schema } from "../editor/schema";
import { handleRawImageUploadPaste } from "../editor/utils/clipboard/imageTools";
import { setHelpSearch } from "../help/Help";
import {
  editorViewAtom,
  getEditorView,
  getSearchBarInput,
  isKeyboardVisibleAtom,
  mobileCloseKeyboardPressedAtom,
  redoDepthAtom,
  undoDepthAtom,
} from "../model/atoms";
import { getUserSettings } from "../model/userSettings";
import { globalAudioRecordingCoordinator } from "../editor/features/audioInsert/audioRecordingCoordinator";
import { setSearchBarAndQuery } from "../search/SearchBar";
import { HELP_SEARCH_ID, MOBILE_BAR_HEIGHT, MOBILE_BAR_ID } from "../utils/constants";
import { alwaysShowMobileKeyboardBar } from "../utils/environment";
import logger from "../utils/logger";
import { referenceAutocompleteTypes } from "../editor/features/autocomplete/modules";
import { colors, shadows, zIndexes } from "../utils/style";
import { useTapOrLongPress } from "../utils/useTapOrLongPress";
import { CircularMenu, NO_STEALING_POINTER_EVENTS, useCircularMenu } from "./CircularMenu";
import { CloseKeyboard } from "./CloseKeyboard";
import { useInsertAudio } from "./header/MicButton";
import { DocumentSplitIcon, LeftIndentIcon, RightIndentIcon } from "./img";
import { TildeIcon } from "./img/TildeIcon";

const activeAreaAtom = atom<"other" | "editor" | "search" | "help">("other");

const iconSize = 25;

const MobileBar = styled.div`
  background-color: ${colors.bg.secondary};
  border: 1px solid ${colors.border.secondarySolid};
  position: fixed;
  width: 100%;
  height: ${MOBILE_BAR_HEIGHT}px;
  z-index: ${zIndexes.keyboardBar};
  display: none;
  box-shadow: ${shadows.heavy};
  justify-content: space-between;
  align-items: center;

  .mobile-bar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    /* To make the target area bigger than it appears, we add extra padding to
    the top of the button and then negative margin to offset it so the bar
    itself doesn't appear taller */
    padding: 30px 8px 10px;
    margin-top: -20px;
    user-select: none;
    border: 0;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    color: ${colors.text.secondary};

    :active {
      background-color: ${colors.bg.accent.tertiary};
    }
  }

  .mobile-bar-button:disabled {
    opacity: 30%;
  }

  .close-keyboard-button {
    padding-left: 15px;
    padding-right: 10px;
  }

  .close-keyboard-button svg {
    margin-bottom: -4px;
  }

  #mobile-bar-create-note-button {
    color: ${colors.bg.accent.primary};
    padding-left: 12px;
    padding-right: 16px;
  }
`;

/**
 * On mobile devices where the buttons don't fit on the screen, this section
 * makes the bar scrollable.
 */
const ScrollingSection = styled.div`
  height: 100%;
  flex: 1;
  background-image: linear-gradient(to left, ${colors.bg.shadowed} 0, ${colors.bg.secondary} 20px);
  display: flex;
  overflow-x: scroll;
  padding-right: 10px;
`;

const FixedSection = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  .mobile-bar-button {
    padding: 30px 8px 12px;
  }
  overflow: hidden;
`;

const VerticalLine = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${colors.border.secondarySolid};
  // shadow to left
  box-shadow: -1px 0px 1px ${colors.border.secondary};
`;

export function insertTextInEditor(text: string) {
  const view = getEditorView();
  if (!view) return;
  // If the user has selected text, convert it to a hashtag or reference
  if (text === "#" && selectionToHashtag(view.state, view.dispatch)) return;
  if (text === "+" && selectionToReference(view.state, view.dispatch)) return;

  let insertText = ` ${text}`;
  const currentPos = view.state.selection.$from;
  if (currentPos.parent.type === schema.nodes.paragraph) {
    const currentChar = currentPos.parent.textContent[currentPos.parentOffset - 1];
    if (
      currentChar === " " ||
      currentChar === "-" || // For when the user is creating a list, see createNestedListPlugin
      currentPos.parentOffset === 0
    ) {
      insertText = text;
    }
  }

  const tr = view.state.tr.replaceWith(view.state.selection.from, view.state.selection.to, schema.text(insertText));

  const newPos = view.state.selection.from + insertText.length;
  tr.setSelection(Selection.near(tr.doc.resolve(newPos)));
  tr.scrollIntoView();
  if (text === "#") {
    tr.setMeta("type", "mobileBarHashtagInsert");
  } else {
    tr.setMeta("type", "mobileBarInsert");
  }

  view.dispatch(tr);

  // Track event
  if (text === "+") {
    trackEvent("mobile_bar_plus_pressed");
  } else if (text === "#") {
    trackEvent("mobile_bar_hashtag_pressed");
  } else if (text === "@") {
    trackEvent("mobile_bar_at_sign_pressed");
  } else if (text === "~") {
    trackEvent("mobile_bar_tilde_sign_pressed");
  }
}

/** Insert text into search bar at selection*/
const insertTextInSearchBar = (text: string) => {
  // Get search input element
  const searchInput = getSearchBarInput();
  if (!searchInput) {
    logger.warn("Couldn't find search input element");
    return;
  }
  // Get selection start and end
  const start = searchInput.selectionStart;
  const end = searchInput.selectionEnd;
  if (document.activeElement !== searchInput || start === null || end === null) {
    logger.warn("Tried to use insertIntoSearchBar without focus on the search input element");
    return;
  }
  // Insert text at selection
  const left = searchInput.value.slice(0, start);
  const right = searchInput.value.slice(end);
  const insertText = !left || left.endsWith(" ") ? text : ` ${text}`;
  setSearchBarAndQuery(left + insertText + right);
};

/** Append text to help search */
const appendToHelpSearch = (text: string) => {
  setHelpSearch((s) => s + (s.endsWith(" ") ? text : ` ${text}`));
};

function MobileBarButton({
  children,
  editorAction,
  searchAction,
  helpAction,
  style,
  disabled,
  active,
}: {
  insertText?: string;
  editorAction: string | (() => void);
  searchAction?: string | (() => void);
  helpAction?: string | (() => void);
  disabled?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
  active?: boolean;
}) {
  const activeArea = useAtomValue(activeAreaAtom);
  const editorHandler = typeof editorAction === "string" ? () => insertTextInEditor(editorAction) : editorAction;
  const searchHandler =
    typeof searchAction === "string"
      ? () => insertTextInSearchBar(searchAction)
      : typeof searchAction === "function"
        ? searchAction
        : undefined;
  const helpHandler =
    typeof helpAction === "string"
      ? () => appendToHelpSearch(helpAction)
      : typeof helpAction === "function"
        ? helpAction
        : undefined;
  const hasHandlerForActiveArea =
    activeArea === "editor"
      ? !!editorHandler
      : activeArea === "search"
        ? !!searchHandler
        : activeArea === "help"
          ? !!helpHandler
          : false;
  const newStyle = { ...style };
  if (active) {
    newStyle.backgroundColor = colors.bg.accent.tertiary;
  }

  return (
    <button
      className="mobile-bar-button"
      style={newStyle}
      onClick={() => {
        if (activeArea === "editor") {
          editorHandler();
        } else if (activeArea === "search") {
          searchHandler?.();
        } else if (activeArea === "help") {
          helpHandler?.();
        }
      }}
      disabled={disabled ?? !hasHandlerForActiveArea}
    >
      {children}
    </button>
  );
}

export const MobileKeyboardBar = () => {
  const view = useAtomValue(editorViewAtom);
  return view ? <Bar view={view} /> : null;
};

function AddImageButton() {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;
    const handleOpenCameraRoll = (event: any) => {
      const file = (event.target as HTMLInputElement).files![0];
      const view = getEditorView();
      if (file && view) {
        handleRawImageUploadPaste(file, view);
      }
    };
    el.addEventListener("change", handleOpenCameraRoll);
    return () => {
      el.removeEventListener("change", handleOpenCameraRoll);
    };
  }, []);
  return (
    <>
      <input ref={ref} type="file" accept="image/*" id="galleryInput" style={{ display: "none" }} />
      <MobileBarButton
        editorAction={() => {
          ref.current?.click();
          trackEvent("open_camera_roll_from_mobile_bar");
        }}
      >
        <ImageIcon size={iconSize} />
      </MobileBarButton>
    </>
  );
}

function TakePhotoButton() {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;
    const handleOpenCameraRoll = (event: any) => {
      const file = (event.target as HTMLInputElement).files![0];
      const view = getEditorView();
      if (file && view) {
        handleRawImageUploadPaste(file, view);
      }
    };
    el.addEventListener("change", handleOpenCameraRoll);
    return () => {
      el.removeEventListener("change", handleOpenCameraRoll);
    };
  }, []);
  return (
    <>
      <input
        ref={ref}
        type="file"
        accept="image/*"
        capture="environment"
        id="galleryInput"
        style={{ display: "none" }}
      />
      <MobileBarButton
        editorAction={() => {
          ref.current?.click();
          trackEvent("open_camera_roll_from_mobile_bar");
        }}
      >
        <Camera size={iconSize} />
      </MobileBarButton>
    </>
  );
}

const Bar = ({ view }: { view: EditorView }) => {
  // keyboardBar is a ref to the DOM element, but since we're using it in a useEffect callback
  // we can't use useRef() directly. See https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
  const [keyboardBar, setKeyboardBar] = useState<HTMLElement | null>(null);
  const isKeyboardVisible = useAtomValue(isKeyboardVisibleAtom);
  const setCloseKeyboardPressed = useSetAtom(mobileCloseKeyboardPressedAtom);
  const setActiveArea = useSetAtom(activeAreaAtom);
  const insertAudio = useInsertAudio();
  const autocompleteState = useAtomValue(autocompleteStateAtom);

  const recordingState = globalAudioRecordingCoordinator.useRecordingState();
  const isRecording = recordingState?.state === "recording";

  const { buttons, isCircularMenuOpen, handlePress, handleMove, handleRelease } = useCircularMenu(false);
  const { handleDown: handleMicDown, handleUp: handleMicUp } = useTapOrLongPress({
    onTap: () =>
      isRecording ? globalAudioRecordingCoordinator.stopRecording(recordingState.audioId) : insertAudio(true),
    onLongPress: () =>
      isRecording ? globalAudioRecordingCoordinator.stopRecording(recordingState.audioId) : insertAudio(false),
  });

  const undoDepth = useAtomValue(undoDepthAtom);
  const redoDepth = useAtomValue(redoDepthAtom);

  // Reposition mobile keyboard bar in response to changes to virtual keyboard,
  // orientation, scroll, and focus
  const repositionBar = useCallback(() => {
    const activeArea = view.hasFocus()
      ? "editor"
      : document.activeElement === getSearchBarInput()
        ? "search"
        : document.activeElement === document.getElementById(HELP_SEARCH_ID)
          ? "help"
          : "other";
    if (!keyboardBar || !window.visualViewport) return;
    if (isKeyboardVisible || alwaysShowMobileKeyboardBar) {
      keyboardBar.style.display = "flex";
      keyboardBar.style.top = `${
        window.visualViewport.height + window.visualViewport.pageTop - keyboardBar.clientHeight
      }px`;
    } else {
      keyboardBar.style.display = "none";
    }
    setActiveArea(activeArea);
  }, [keyboardBar, isKeyboardVisible, view, setActiveArea]);

  const hideBar = useCallback(() => {
    if (!keyboardBar) return;
    keyboardBar.style.display = "none";
  }, [keyboardBar]);

  useEffect(repositionBar, [isKeyboardVisible, repositionBar]);
  useEffect(() => {
    // This hides the bar during orientation change. Once the change is finished,
    // a "resize" event will trigger repositioning the bar
    window.addEventListener("orientationchange", hideBar);
    // Repositions the bar when the keyboard bar goes up/down and when the orientation
    // finishes changing.
    window.visualViewport?.addEventListener("resize", repositionBar);
    // Repositions the bar on scroll
    window.visualViewport?.addEventListener("scroll", repositionBar);
    // Hides/shows the bar when you change focus between editor and search bar
    view.dom.addEventListener("focus", repositionBar);
    getSearchBarInput()?.addEventListener("focus", repositionBar);

    return () => {
      window.visualViewport?.removeEventListener("resize", repositionBar);
      window.visualViewport?.removeEventListener("scroll", repositionBar);
      window.removeEventListener("click", repositionBar);
      window.removeEventListener("orientationchange", hideBar);
      view.dom.removeEventListener("focus", repositionBar);
      getSearchBarInput()?.removeEventListener("focus", repositionBar);
    };
  }, [hideBar, repositionBar, view]);

  return (
    <MobileBar
      ref={setKeyboardBar}
      id={MOBILE_BAR_ID}
      role="presentation"
      // Prevent default editor bluring behaviour when the user clicks/taps on the mobile bar outside of a button
      // This also fixes the button double clicking bluring the editor (https://linear.app/ideaflow/issue/ENT-2073/bug-pressing-new-note-on-mobile-keyboard-bar-in-quick-succession)
      onMouseDown={(e) => e.preventDefault()}
      onMouseUp={(e) => e.preventDefault()}
    >
      <ScrollingSection>
        <MobileBarButton
          editorAction={() => {
            if (!referenceAutocompleteTypes.includes((autocompleteState?.matcherName ?? "") as any)) {
              // If there's no active reference autocomplete, just insert a plus
              insertTextInEditor("+");
            } else {
              // If there is an active reference autocomplete, pressing + should
              // accept the current suggestion and then insert a +. This is
              // already implemented in the autocompletes plugin's keydown
              // handler, so we just dispatch a keydown event to trigger it.
              // Dispatching a keydown feels a bit hacky, but I don't want to
              // duplicate the logic here, and refactoring the autocomplete
              // plugin to extract the logic into a separate function would be a
              // lot of work.
              view.dispatchEvent(new KeyboardEvent("keydown", { key: "+" }));
            }
          }}
          searchAction="+"
          helpAction="relation"
          active={autocompleteState?.matcherName === "plusReference"}
        >
          <Plus size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          editorAction="#"
          searchAction="#"
          helpAction="hashtag"
          active={autocompleteState?.matcherName === "hashtag"}
        >
          <Hash size={iconSize} />
        </MobileBarButton>
        {getUserSettings().atSignHashtagEnabled && (
          <MobileBarButton editorAction="@" searchAction="@" helpAction="@">
            <AtSign size={0.9 * iconSize} />
          </MobileBarButton>
        )}
        <MobileBarButton
          editorAction={() => {
            toggleCheckboxCommand(view.state, view.dispatch);
            trackEvent("toggle_checkbox_from_mobile_bar");
          }}
          searchAction={`"[ ]" `}
          helpAction="to-do"
        >
          <CheckSquare />
        </MobileBarButton>
        <MobileBarButton
          editorAction={() => {
            const tr = listCreateOrWrap(view.state, false);
            if (!tr) return;
            tr.setSelection(Selection.near(tr.doc.resolve(view.state.selection.to + 2)));
            tr.scrollIntoView();
            view.dispatch(tr);
            trackEvent("create_list_from_mobile_bar");
          }}
        >
          <List size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          editorAction={() => {
            chainCommands(incrementDepthCommand, indent)(view.state, view.dispatch);
            trackEvent("change_list_depth_from_mobile_bar");
          }}
        >
          <RightIndentIcon size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          editorAction={() => {
            chainCommands(decrementDepthCommand, dedent)(view.state, view.dispatch);
            trackEvent("change_list_depth_from_mobile_bar");
          }}
        >
          <LeftIndentIcon size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          style={{
            marginRight: -3,
            opacity: undoDepth > 0 ? 1 : 0.3,
          }}
          editorAction={() => {
            undo(view.state, view.dispatch);
            trackEvent("undo_from_mobile_bar");
          }}
        >
          <RotateCcw size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          style={{
            marginLeft: -3,
            opacity: redoDepth > 0 ? 1 : 0.3,
          }}
          editorAction={() => {
            redo(view.state, view.dispatch);
            trackEvent("redo_from_mobile_bar");
          }}
        >
          <RotateCw size={iconSize} />
        </MobileBarButton>
        {getUserSettings().tildeSignHashtagEnabled && (
          <MobileBarButton editorAction="~" searchAction="~" helpAction="~">
            <TildeIcon size={0.9 * iconSize} />
          </MobileBarButton>
        )}
        <MobileBarButton
          editorAction={() => {
            splitNoteCommand(view.state, view.dispatch);
          }}
        >
          <DocumentSplitIcon size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          editorAction={() => {
            document.execCommand("paste");
          }}
          searchAction={() => {
            document.execCommand("paste");
          }}
          helpAction={() => {
            document.execCommand("paste");
          }}
        >
          <Clipboard size={iconSize} />
        </MobileBarButton>
        <MobileBarButton
          editorAction="<> "
          searchAction="<> "
          helpAction="relation"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <ChevronLeft size={iconSize} style={{ marginRight: -10 }} />
          <ChevronRight size={iconSize} />
        </MobileBarButton>
        <TakePhotoButton />
        <AddImageButton />
      </ScrollingSection>
      <VerticalLine />
      <FixedSection>
        <button
          className="mobile-bar-button close-keyboard-button"
          onMouseDown={(e) => {
            e.preventDefault();
            (document.activeElement as HTMLElement).blur();
            setCloseKeyboardPressed(true);
          }}
          // without this, the editor can lose focus when the button is clicked near the top edge (ENT-1881)
          onClick={(e) => e.preventDefault()}
        >
          <CloseKeyboard size={1.2 * iconSize} />
        </button>
        <button
          id="mobile-bar-start-recording-button"
          className="mobile-bar-button"
          // without this, the editor can lose focus when the button is clicked near the top edge (ENT-1881)
          onClick={(e) => e.preventDefault()}
          onPointerDown={handleMicDown}
          onPointerUp={handleMicUp}
          style={NO_STEALING_POINTER_EVENTS}
        >
          {isRecording ? <StopCircle size={1.2 * iconSize} /> : <Mic size={1.2 * iconSize} />}
        </button>
        <button
          id="mobile-bar-create-note-button"
          className="mobile-bar-button"
          // without this, the editor can lose focus when the button is clicked near the top edge (ENT-1881)
          onClick={(e) => e.preventDefault()}
          onPointerDown={handlePress}
          onPointerMove={handleMove}
          onPointerUp={handleRelease}
          style={NO_STEALING_POINTER_EVENTS}
        >
          <Edit size={1.2 * iconSize} />
          <CircularMenu isCircularMenuOpen={isCircularMenuOpen} buttons={buttons} />
        </button>
      </FixedSection>
    </MobileBar>
  );
};
