import { debounce } from "lodash";
import { Selection } from "prosemirror-state";
import React, { useCallback, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { trackEvent } from "../analytics/analyticsHandlers";
import { useHome } from "../editorPage/navigateHistory";
import { getEditorView, getSearchBarInput, setSearchBarRef } from "../model/atoms";
import { withCloseModals } from "../model/modals";
import { useHotkeys } from "../shortcuts/useHotkeys";
import { useShortcuts } from "../shortcuts/useShortcuts";
import { isMobile, isTouchDevice } from "../utils/environment";
import { GenericSearchBar } from "../components/GenericSearchBar";
import { useCreateNoteAtTop } from "../editorPage/hooks/useCreateNote";
import { ShortcutIcons } from "../shortcuts/KeyIcons";
import { colors } from "../utils/style";
import { getShortcutString } from "../shortcuts/shortcuts";
import { DesktopSearchSuggestionList } from "./DesktopSearchSuggestionList";
import { MobileSearchSuggestionList } from "./MobileSearchSuggestionList";
import { queryFromSearchString, searchQueryToText } from "./SearchQuery";
import { setSearchInputText, useSearchInputText } from "./searchInputText";
import { updateSearchQuery, useSearchQuery } from "./useSearchQuery";

const Container = styled.div`
  flex: 1;
`;

const Search = () => {
  const searchQuery = useSearchQuery();
  const inputText = useSearchInputText();
  const { shortcuts } = useShortcuts();
  const createNewNote = useCreateNoteAtTop();
  const home = useHome();
  const [hasFocus, setHasFocus] = React.useState(false);

  // Assign the search bar ref to the global atom
  const searchBarRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setSearchBarRef(searchBarRef);
  }, []);

  // Ref to the keydown handler for the suggestions list
  const suggestionsKeyDownRef = useRef<((event: React.KeyboardEvent<HTMLInputElement>) => boolean) | null>(null);

  // Set search bar to query, unless query is from search bar
  useEffect(() => {
    if (searchQuery.source === "searchBar") return;
    setSearchInputText(searchQueryToText(searchQuery));
  }, [searchQuery]);

  // When features update the search bar text, it can move the cursor out of
  // view. This effect scrolls the cursor back into view.
  useEffect(() => {
    const input = getSearchBarInput();
    if (!input || document.activeElement !== input) return;
    if (input.selectionStart !== inputText.length) return;
    console.log("scrolling cursor into view");
    input.setSelectionRange(inputText.length, inputText.length);
    input.scrollLeft = input.scrollWidth;
  }, [inputText]);

  // Focus searchbar hotkey
  const focusInput = withCloseModals(() => getSearchBarInput()?.focus());
  useHotkeys(shortcuts.search, focusInput);

  // Create new note from search bar hotkey
  useHotkeys(
    shortcuts.createNewNoteFromSearch,
    withCloseModals(() => {
      if (hasFocus && inputText) {
        createNewNote(inputText);
      }
    }),
  );

  const CreateNoteFromSearchButton = useCallback(() => {
    if (!hasFocus || isMobile) return null;
    return (
      <button
        style={{
          background: colors.bg.secondary,
          border: 0,
          display: "inline",
          padding: 0,
          paddingLeft: 10,
          margin: 0,
          cursor: "pointer",
          color: colors.text.tertiary,
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          createNewNote(inputText);
          trackEvent("create_note_from_search_bar");
        }}
      >
        Create Note (<ShortcutIcons keys={shortcuts.createNewNoteFromSearch.keys} />)
      </button>
    );
  }, [inputText, createNewNote, shortcuts.createNewNoteFromSearch.keys, hasFocus]);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    // Give the suggestions list a chance to handle the keydown event first
    if (suggestionsKeyDownRef.current) {
      const handled = suggestionsKeyDownRef.current(e);
      if (handled) {
        e.preventDefault();
        return;
      }
    }
    // if user hits tab, we focus the editor
    const view = getEditorView();
    if (view && e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      const tr = view.state.tr;
      tr.setSelection(Selection.atStart(tr.doc)).scrollIntoView();
      view.dispatch(tr);
      view.focus();
    }
  }, []);

  return (
    <Container ref={searchBarRef}>
      <GenericSearchBar
        id={"ideasearch"}
        inputText={inputText}
        placeholder={`Search Notes${!isTouchDevice ? " " + getShortcutString(shortcuts.search) : ""}`}
        onKeyDown={onKeyDown}
        setSearch={(text) => {
          setSearchInputText(text);
          debouncedSetQueryToSearch(text);
        }}
        clear={() => {
          home();
          focusInput();
        }}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        ActionButton={CreateNoteFromSearchButton}
      />
      {hasFocus &&
        (isMobile ? (
          <MobileSearchSuggestionList keyDownHandlerRef={suggestionsKeyDownRef} />
        ) : (
          <DesktopSearchSuggestionList keyDownHandlerRef={suggestionsKeyDownRef} />
        ))}
    </Container>
  );
};
const debouncedSetQueryToSearch = debounce(
  (value: string) => {
    updateSearchQuery({
      ...queryFromSearchString(value),
      source: "searchBar",
    });
    trackEvent("search_from_search_bar");
  },
  200,
  {
    // Don't fire on the first keystroke -- wait until user stops typing
    leading: false,
  },
);
/**
 *
 * Set the search bar text and search query to the given text.
 *
 * Note: this behaves the same as if the user had typed the text into the search
 * bar, which means updating the search query is debounced a little, and
 * updating the url debounced even a lot.
 */
export const setSearchBarAndQuery = (text: string) => {
  setSearchInputText(text);
  debouncedSetQueryToSearch(text);
};
/**
 * Immediately clear the search bar, search query, and update url to match.
 */
export const clearSearchBarAndQuery = () => {
  setSearchInputText("");
  updateSearchQuery({ source: "other" });
};
// Header is rerendered every time the view changes
// Search doesn't need to know anything about the editorView
export default React.memo(Search);
