import posthog from "posthog-js";
import { getDefaultStore } from "jotai";
import { accessTokenAtom, getIsOnline, getUserEmail, getUserId } from "../model/atoms";
import {
  Env,
  env,
  isAnalyticsEnabled,
  isAndroid,
  isContinuousIntegration,
  isIOs,
  isMac,
  isMobile,
  isRunningInIOSWebview,
  sentryRelease,
} from "../utils/environment";
import appLogger from "../utils/logger";
import { ApiClient } from "../api/client";
import analyticsCache from "./analyticsCache";

const logger = appLogger.with({ namespace: "analytics" });

type AnalyticsEventName =
  | "session_started"
  | "focus_window"
  | "blur_window"
  | "keyboard_shortcut"
  | "jump_to_note"
  | "transcribe_recording"
  | "create_note_from_relation"
  | "hashtag_created"
  | "hashtag_added"
  | "create_relation"
  | "append_expanded_relation"
  | "toggle_relation"
  | "toggle_checkbox_from_key"
  | "create_list"
  | "merge_note"
  | "split_note"
  | "split_note_from_folder"
  | "split_note_with_dashes"
  | "split_note_with_dashes_from_folder"
  | "create_note"
  | "create_note_from_folder"
  | "create_note_as_page"
  | "delete_note_from_folder"
  | "delete_note"
  | "update_note"
  | "update_note_from_expansion"
  | "mobile_bar_plus_pressed"
  | "mobile_bar_hashtag_pressed"
  | "create_list_from_mobile_bar"
  | "change_list_depth_from_mobile_bar"
  | "toggle_checkbox_from_mobile_bar"
  | "undo_from_mobile_bar"
  | "redo_from_mobile_bar"
  | "sort_by_relevance"
  | "sort_by_last_updated"
  | "sort_by_oldest"
  | "sort_by_position"
  | "toggle_compact_view_on"
  | "toggle_compact_view_off"
  | "toggle_with_subfolders_on"
  | "toggle_with_subfolders_off"
  | "toggle_sidebar_from_header"
  | "navigate_home_from_header"
  | "navigate_back_from_header"
  | "open_help_from_audio"
  | "open_help_from_header"
  | "open_help_from_hotkeys"
  | "open_help_from_onboarding"
  | "open_help_from_sidebar"
  | "open_settings_from_hotkeys"
  | "open_settings_from_sidebar"
  | "share_queried_notes"
  | "navigate_home_from_shortcut"
  | "navigate_history_from_browser"
  | "remove_note_from_folder"
  | "update_note_in_folder"
  | "add_note_to_folder"
  | "toggle_note_pinned"
  | "hashtag_pinned"
  | "hashtag_unpinned"
  | "open_note_as_page"
  | "copy_note_text"
  | "copy_note_url"
  | "export_to_json"
  | "export_to_plaintext"
  | "import_from_json"
  | "import_from_plaintext"
  | "import_from_markdown"
  | "import_from_clipboard"
  | "import_from_apple_notes"
  | "search_from_search_bar"
  | "search_from_sidebar"
  | "create_folder"
  | "update_folder"
  | "update_hashtag"
  | "delete_folder"
  | "extract_entities"
  | "at_sign_created"
  | "tilde_sign_created"
  | "mobile_bar_at_sign_pressed"
  | "mobile_bar_tilde_sign_pressed"
  | "offline_warning_shown"
  | "take_picture_from_mobile_bar"
  | "open_camera_roll_from_mobile_bar"
  | "heap_memory_usage"
  | "onboarding_next_clicked"
  | "onboarding_closed_early"
  | "onboarding_finished"
  | "create_note_from_search_bar";

type AnalyticsEvent = {
  name: AnalyticsEventName;
  value?: string | number;
  user_id: string;
  user_email: string;
  env: Env;
  isMac: boolean;
  isIOs: boolean;
  isNativeIOS: boolean;
  isAndroid: boolean;
  isMobile: boolean;
  timestamp: number;
  offline: boolean;
  qt?: number;
};

function isAnalyticsEvent(event: unknown): event is AnalyticsEvent {
  return (
    typeof event === "object" &&
    event !== null &&
    "name" in event &&
    "user_id" in event &&
    "user_email" in event &&
    "env" in event &&
    "offline" in event &&
    "isMac" in event &&
    "isIOs" in event &&
    "isNativeIOS" in event &&
    "isAndroid" in event &&
    "isMobile" in event &&
    "timestamp" in event
  );
}

const trackEventOnRDS = (event: AnalyticsEvent) => {
  if (isContinuousIntegration || !getDefaultStore().get(accessTokenAtom)) return;
  const platform = event.isNativeIOS
    ? "Native iOS"
    : event.isIOs
      ? "iOS browser or PWA"
      : event.isAndroid
        ? "Android"
        : event.isMac
          ? "Mac"
          : event.isMobile
            ? "Other mobile"
            : "Other desktop";
  const eventValue = event.value !== null ? { value: event.value } : {};
  const eventBlob = {};
  const timestamp = new Date().toISOString();
  const apiClient = ApiClient();
  const version = sentryRelease;

  apiClient.analytics.track(
    event.user_id,
    event.user_email,
    event.name,
    eventValue,
    eventBlob,
    timestamp,
    platform,
    env,
    event.offline,
    version,
  );
};

const trackPosthogEvent = (event: AnalyticsEvent) => {
  try {
    if (posthog.get_distinct_id() !== event.user_id) {
      posthog.identify(event.user_id, { email: event.user_email });
    }
    const props: any = { ...event, environment: event.env };
    delete props.env;
    posthog.capture(event.name, props);
  } catch (error) {
    logger.error("Error sending posthog event", { error });
  }
};

export const retryOfflineEvents = async () => {
  if (!isAnalyticsEnabled || !getIsOnline()) return;
  const keys = await analyticsCache.findAllKeys();
  keys.forEach(async (key) => {
    const offlineEvent = await analyticsCache.getItem(key.toString());
    analyticsCache.removeItem(key.toString());
    if (!isAnalyticsEvent(offlineEvent)) {
      logger.error("Unexpected value in analytics cache", { context: { offlineEvent } });
      return;
    }
    offlineEvent.offline = true;
    offlineEvent.qt = Date.now() - offlineEvent.timestamp;
    trackPosthogEvent(offlineEvent);
    trackEventOnRDS(offlineEvent);
  });
};

export const trackEvent = (eventName: AnalyticsEventName, value?: string | number) => {
  if (!isAnalyticsEnabled) return;
  logger.info(`${eventName} ${value}`);
  let userId = getUserId();
  if (!userId) {
    logger.warn("Missing user id for analytics", { context: { eventName, userId } });
  }
  userId = userId || "unknown";
  const userEmail = getUserEmail() || "unknown";
  const online = getIsOnline();
  const event: AnalyticsEvent = {
    name: eventName,
    value: value,
    user_id: userId,
    user_email: userEmail,
    env: env,
    offline: !online,
    isMac,
    isIOs,
    isNativeIOS: isRunningInIOSWebview,
    isAndroid,
    isMobile,
    timestamp: Date.now(),
  };
  if (online) {
    trackPosthogEvent(event);
    trackEventOnRDS(event);
  } else {
    analyticsCache.setItem(event.timestamp.toString(), event);
  }
};
